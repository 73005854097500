html, body{
  height:100vh;
  margin:0;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: rgb(247,242,242);
  background: linear-gradient(90deg, rgba(247,242,242,1) 0%, rgba(78,213,70,1) 66%, rgba(44,163,0,1) 100%);
  background-image: url(./components/assets/images/background.jpg);
  background-size: cover;
}
