div.cardimagen.card{
   /* margin: 0px;*/
    margin-bottom: 1.5rem;
}

img.card-img-top.cardimg {
    /*width: 23rem;*/
}

.cardimg {

}

.mt-2 {
    margin: 0;
    padding: 0;
}

div.cardimagen.card {
padding: 0px;
}

div.text-muted.card-footer {
    font-size: 10px;
}

.cardimagen:hover {
    border-color: green;
    border-width: 2px;
}
