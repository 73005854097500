.contenedor {
    margin: 1rem 10px 10px 1rem;
    border: solid 1px gray;
    border-radius: 5px;
    width: 100%;
    height:100%;
    text-align: center;
    margin-bottom: 2.2rem;
}

.mitabs {
      
}

.mitab {

}
