.miFooter {
    height: 2rem;
    display: flex;
    align-items: center;
}

.about {
    display: block;
    width: 100%;
    color: white;
    margin-top: 1rem;
    font-size: smaller;
}